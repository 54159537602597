import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./MainCarousel.module.scss"
import Image from "../../components/Image/Image"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import { Transition } from "react-transition-group"
import Scrollbar from "react-scrollbars-custom"
import Img from "gatsby-image"
import RichTextRender from "../../components/RichTextRender/RichTextRender"

const duration = 300

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

class MainCarousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAutoplayLoaded: true,
      loadText: false,
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isAutoplayLoaded: false,
      })
    }, 2000)
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.titleLoaded != prevProps.titleLoaded && this.props.titleLoaded == true) {
      setTimeout(() => {
        this.setState({
          loadText: true,
        })
      }, 500)
    }
  }
  renderTextBox(title, description, linkTitle, transition, slideItemIndex) {
    return (
      <Transition in={transition} timeout={duration}>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {slideItemIndex == 0 ? (
              <div className={css.textBoxContainer}>
                <div className={css.textBoxWrapper}>
                  <h1>{title}</h1>
                  <Scrollbar style={{ height: 300 }}>{description && description.json && <RichTextRender documentRichText={description.json}></RichTextRender>}</Scrollbar>
                  {/* <div className={css.linkContainer}>{linkTitle}</div> */}
                </div>
              </div>
            ) : (
              <div className={css.slideContainer}>
                <div className={css.slideSeason}>
                  <div>Season limited design</div>
                  <div>Each design and style change very season, Don't worry there's always enough high heel and low heel options for you to choose from</div>
                </div>
                <div className={css.slideDesign}>
                  <div>Designed in Sweden - made in Italy</div>
                  <div>Takes inspiration the Cultural Chalsea movement and for a price you deserve</div>
                </div>
                <div className={css.slideStarRating}>
                  <div className={css.slideStarContainer}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="34" viewBox="0 0 24 24" width="34">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path fill="orange" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" height="34" viewBox="0 0 24 24" width="34">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path fill="orange" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" height="34" viewBox="0 0 24 24" width="34">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path fill="orange" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" height="34" viewBox="0 0 24 24" width="34">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path fill="orange" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" height="34" viewBox="0 0 24 24" width="34">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path fill="orange" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                  </div>
                  <div className={css.slideStarTextContainer}>I got <b>free international shipping</b> to Britian for a <b>goodyear welt</b> at half the price of a Saint Laurent pair. A truly undervalued brand!</div>
                </div>
              </div>
            )}
          </div>
        )}
      </Transition>
    )
  }
  render() {
    return (
      <>
        <div className={css.container}>
          <Carousel
            // autoPlay={this.props.titleLoaded ? 6000 : 1500}
            // animationSpeed={this.props.titleLoaded ? 800 : 1400}
            // animationSpeed={1400}
            keepDirectionWhenDragging={true}
            infinite
            autoPlay={this.state.isAutoplayLoaded ? 1400 : 105000}
          >
            {this.props.data.allContentfulCarouselSlideList.nodes &&
              this.props.data.allContentfulCarouselSlideList.nodes[0].slidesList.map((slideItem, slideItemIndex) => {
                let imageSizes = slideItem.backgroundImage.image.sizes
                return (
                  <div key={slideItemIndex}>
                    {this.renderTextBox(slideItem.name, slideItem.descriptionText, "LEARN MORE", this.state.loadText, slideItemIndex)}
                    <div className={css.imageWrapper}>
                      <Img sizes={imageSizes} className={css.gatsbyImageWrapper} />
                    </div>
                  </div>
                )
              })}
          </Carousel>
        </div>
      </>
    )
  }
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulCarouselSlideList {
          nodes {
            slidesList {
              name
              descriptionText {
                json
              }
              link {
                internalLink
              }
              backgroundImage {
                image {
                  sizes(maxWidth: 1980) {
                    ...GatsbyContentfulSizes
                  }
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <MainCarousel data={data} {...props} />}
  />
)
