import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomePage from "../containers/HomePage/HomePage";
// import Image from "../components/image"
// import Test from "../components/Test/test"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomePage></HomePage>
    {/* <Test></Test> */}
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
