import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./HomePage.module.scss"
import HeadTitle from "../../components/HeadTitle/HeadTitle";
import Image from "../../components/Image/Image";
import HeaderNav from "../HeaderNav/HeaderNav";
import MainCarousel from "../MainCarousel/MainCarousel";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleLoaded: false
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ titleLoaded: true });
    }, 1500);

    // identify where user is coming from
    // console.log("ipinfo.io/json");
    // let url = "http://ip-api.com/json";
    // return fetch(url)
    // .then(data => {
    //   console.log("data - json data");
    //   console.log(data);
    // })
  }
  render() {
    return (
      <div className={css.container}>
        <div className={[this.state.titleLoaded ? css.navHeaderContainerTransition : css.navHeaderContainer, css.headerContainer].join(' ')}>
          <HeaderNav titleLoaded={this.state.titleLoaded} backgroundColor="white"></HeaderNav>
        </div>
        {!this.state.titleLoaded && <HeadTitle text={"The Chelsea Boot"} subtext="Story Et Fall Community Edition"></HeadTitle>}
        <div className={[css.mainBackgroundImage, css.transition, this.state.titleLoaded ? css.mainBackgroundImageDiscludeHeader : ''].join(' ')}>
          {/* <Image alt="background" src={this.props.data.contentfulBackgroundImage.backgroundImage.image.file.url}></Image> */}
          <div className={css.backgroundGradient}></div>
        </div>
        <MainCarousel titleLoaded={this.state.titleLoaded} imageUrl={this.props.data.contentfulBackgroundImage.backgroundImage.image.file.url}></MainCarousel>
      </div>
    )
  }
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulBackgroundImage {
            backgroundImage {
              image {
                file {
                  url
                }
              }
            }
          }
      }
    `}
    render={data => <HomePage data={data} {...props} />}
  />
)
