import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./HeadTitle.module.scss"
import anime from "animejs/lib/anime.es.js"
import $ from "jquery"

export default class HeadTitle extends Component {
  componentDidMount() {
    // Wrap every letter in a span
    $(".ml14 .letters").each(function() {
      $(this).html(
        $(this)
          .text()
          .replace(/([^\x00-\x80]|\w)/g, "<span className='letter'>$&</span>")
      )
    })

    anime
      .timeline({ loop: false })
      .add({
        targets: ".ml14 .line",
        scaleX: [0, 1],
        opacity: [0.5, 1],
        easing: "easeInOutExpo",
        duration: 1000,
      })
      .add({
        targets: ".ml14 .letter",
        opacity: [0, 1],
        translateX: [40, 0],
        translateZ: 0,
        scaleX: [0.3, 1],
        easing: "easeOutExpo",
        duration: 300,
        offset: "-=600",
        delay: function(el, i) {
          return 150 + 25 * i
        },
      })
      .add({
        targets: ".ml14",
        opacity: 0,
        duration: 500,
        easing: "easeOutExpo",
        delay: 100,
      })
  }
  render() {
    return (
      <div className={css.container}>
        <h1 className="ml14">
          <span className="text-wrapper">
            {this.props.text && (
              <div className={css.letterWrapper}>
                <span className={["letters", css.title].join(' ')}>{this.props.text}</span>
                <div className={["letters", css.subtitle].join(' ')}>{this.props.subtext}</div>
                <span className="line"></span>
              </div>
            )}
          </span>
        </h1>
      </div>
    )
  }
}
